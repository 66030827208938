<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom" v-model="tabIndex">
			            <b-tab title="Basic Info" active class="overview-tab">	
			                <validation-observer
						        ref="editOr_rel"
						        #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6">
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">

							            		<template v-if="$route.name == 'itees-organisations-edit'">
								            		<template v-if="(userData && permission.edit)">

									                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

										                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
										                    Save
										                </b-button>
										                
										            </template>
										            <template v-else>
										            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                    Edit
										                </b-button>
										            </template>
										        </template>
										        <template v-else>
										        	<template v-if="(userData && permission.edit)">
										        		 <b-button type="button" variant="primary" class="btn-df size-18 black"  @click="toEdit(detail.id)">
										                    Edit
										                </b-button>
										        	</template>
										        	<template v-else>
										            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                    Edit
										                </b-button>
										            </template>
										        </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Organisation Name" label-cols-lg="2" class="star-required input-group-label">
				                              		<validation-provider
						                              	#default="{ errors }"
						                              	name="Organisation Name"
						                              	rules="required"                          
						                            > 
					                                    <b-form-input
					                                    	v-model="detail.name"
					                                      	placeholder="Name"
					                                      	:disabled="isDisabled"
					                                      	:formatter="length100"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Abbreviation" label-cols-lg="2" class="star-required input-group-label">
					                              	<validation-provider
						                              	#default="{ errors }"
						                              	name="Abbreviation"
						                              	rules="required"                          
						                            >           	              
					                                    <b-form-input
					                                    	v-model="detail.abbreviation"
					                                      	placeholder="ORG1"
					                                      	:disabled="isDisabled"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Country" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Country"
						                              	rules="required"                          
						                            >
						                                <v-select
						                                	v-model="selectedCountry"
						                                    label="name"
						                                    :options="selectCountry"
						                                    placeholder="Select Country"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">
					                            	<validation-provider
						                              	#default="{ errors }"
						                              	name="Status"
						                              	rules="required"                          
						                            >	
						                                <v-select
						                                	v-model="selectedStatus"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select Status"
						                                    :disabled="isDisabled"
                              								:id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 		                          	
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Description" label-cols-lg="2">
					                            	<b-form-textarea
					                            		v-model="detail.description"
					                                    placeholder="Description"
					                                    rows="4"
					                                    :formatter="length1000"
					                                    :disabled="isDisabled"
					                                ></b-form-textarea>
					                            </b-form-group>
					                            <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col> 
				                          	<b-col lg="6" class="px-0 cb-aligncenter pl-10t">  
				                          		<b-form-group label="Is Client" label-cols-lg="4">
													<b-form-radio-group
												      v-model="selectedClient"
												      :options="optionsClient"
												      :disabled="isDisabled"
												    >
												    </b-form-radio-group>
												</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0 cb-aligncenter pl-10t">  
				                          		<b-form-group label="Is Partner" label-cols-lg="4">
													<b-form-radio-group
												      v-model="selectedPartner"
												      :options="optionsPartner"
												      :disabled="isDisabled"
												    >
												    </b-form-radio-group>
												</b-form-group>
				                          	</b-col> 
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>   
			            <b-tab title="Alumni Statistic" class="tab-child">
			            	<b-tabs card class="caption-add table-custom" v-model="tabIndexAlumni">
					        	<b-tab title="Direct">					        		
					        		<b-row class="mb-20">
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
														</svg>
							        				</span>
							        				Total
							        			</div>
							        			<div>{{ charHead.total}}</div>
							        		</div>
							        	</b-col>
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
														</svg>
							        				</span>
							        				Leaders
							        			</div>
							        			<div>{{ charHead.leader}}</div>
							        		</div>
							        	</b-col>
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
														</svg>
							        				</span>
							        				Specialists
							        			</div>
							        			<div>{{ charHead.speacialist}}</div>
							        		</div>
							        	</b-col>
							        </b-row>		        	
						        	<b-card no-body class="mb-20">
										<b-card-header class="pt-2 pb-1 pl-2 frm-basic">
											<b-row style="width: 100%; margin: 0;">
												<b-col lg="4" class="px-0">  
													<b-card-title>Latest Statistics</b-card-title>
												</b-col>	
									        	<b-col lg="8" class="cadendar-chart" style="display: flex; justify-content: flex-end;">  
								                    <b-form-group style="width: 230px; display: inline-block" class="mb-0 mr-1">	
						                                <v-select
						                                	label="title"
						                                    :options="optionsTypeChart"
						                                    v-model="selectedTypeChart" 
					                                    />
						                            </b-form-group>  
								                    <div class="border-chart" v-if="selectedTypeChart.value == 1">
											        	<feather-icon
												          icon="CalendarIcon"
												          size="14"
												        />
											        	<flat-pickr
												          	v-model="rangePicker"
												          	:config="{ 
												          		mode: 'range', altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d' ,
												          		onChange: function (selectedDates, dateStr, instance) {

															        if (selectedDates.length == 2) {
															        	this.flatMess = false
															            var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
															            range = range / 86400;

															            if(range > 30)
															            {		                
															                instance.clear()
															                alert();
															            }          
															        }
															    },
															    onOpen: [
															    	function(selectedDates, dateStr, instance){
													            		setMess()
													        		},
													      		],
												          	}"

												          	class="form-control flat-picker bg-transparent border-0 shadow-none"
												          	placeholder="Select Date"
												        />
						                            </div>   
						                            <div class="border-chart pl-0" v-else-if="selectedTypeChart.value == 2">
											        	<datepicker        
												        	v-model="selectedMonthYear"
					                                    	format="MM/yyyy" 
					                                    	minimum-view="month"
					                                    	placeholder="Select Month"
					                                    	class="w-100-child"
					                                    >              	
					                                    </datepicker>
						                            </div>  
						                            <div class="border-chart pl-0" v-else-if="selectedTypeChart.value == 3">
											        	<datepicker        
												        	v-model="selectedYear"
					                                    	format="yyyy" 
					                                    	minimum-view="year"
					                                    	placeholder="Select Year"
					                                    	class="w-100-child"
					                                    >              	
					                                    </datepicker>
						                            </div>  
					                          	</b-col> 
									        </b-row>	
										</b-card-header>
									    <b-card-body class="pb-1" v-if="tabIndex == 1">
										    <vue-apex-charts
										        height="600"
										        :options="chartOptions"
										        :series="series"
										        show="true"
										    />
									    </b-card-body>
									</b-card>
									<b-card class="px-table-0 w-15-percent-cl-1 w-20-percent-cl-2 w-30-percent-cl-3">
							        	<b-row>
								            <b-col lg="6" style="padding-left: 30px ">
								                <h2 class="mb-2"><strong>Activity Contribution</strong></h2>
								            </b-col>
								        </b-row>
									    <b-table small :fields="fields_activity_contribution" :items="activity_contribution" tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked">
									    	<template #head(activity_id)="data">
									    		<span>{{ data.label }}</span>
									    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 45px;">
										        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
										        </span>
									    	</template>
									    	<template #head(activity_name)="data">
									    		<span>{{ data.label }}</span>
									    	</template>	
									    	<template #head(project_name)="data">
									    		<span class="d-block text-center">{{ data.label }}</span>
									    	</template>		
									    	<template #head()="data">
									    		<div class="d-block text-center">
									    			<span>{{ data.label }}</span>
									    			<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
											        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
											        </span>
									    		</div>
									    	</template>	
									    	<template #cell(activity_id)="data">
												<span>{{ data.item.activity_id }}</span>
											</template>	
									    	<template #cell(activity_name)="data">
												<span>{{ data.item.activity_name }}</span>
											</template>	
											<template #cell()="data">
												<span class="d-block text-center">{{ data.value }}</span>
											</template>				
											<template #empty="scope">
										      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
										    </template>
										</b-table>
									</b-card>
									<div v-if="this.total > 0" class="paging-cus relative">
										<b-pagination
									      	v-model="currentPage"
									      	:total-rows="total"
									      	:per-page="perPage"
									      	aria-controls="custom-table"
									      	first-number
								        	last-number
								        	class="just-center"
									    ></b-pagination>
									    <div class="limit-per">
											<select v-model="selected" class="cursor-pointer">
											    <option v-for="limit in limitpage">{{ limit }}</option>
											</select>			  
									    </div>
									</div> 
					        	</b-tab>
					        	<b-tab title="Indirect">
					        		<b-row class="mb-20">
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
														</svg>
							        				</span>
							        				Total
							        			</div>
							        			<div>{{ charHead.total}}</div>
							        		</div>
							        	</b-col>
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
														</svg>
							        				</span>
							        				Leaders
							        			</div>
							        			<div>{{ charHead.leader}}</div>
							        		</div>
							        	</b-col>
							        	<b-col lg=4>
							        		<div class="box">
							        			<div>
							        				<span class="ico mr-05">
							        					<svg style="width:30px;height:30px" viewBox="0 0 24 24">
														    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
														</svg>
							        				</span>
							        				Specialists
							        			</div>
							        			<div>{{ charHead.speacialist}}</div>
							        		</div>
							        	</b-col>
							        </b-row>	
							        <Indirect :tabChart="tabIndexAlumni" />
					        	</b-tab>
					        </b-tabs>
			            </b-tab>  	
			        </b-tabs>  			        
			    </div>
			</b-col>
		</b-row>
	</section>
</template>

<script>

	const moment = require('moment')
	import Breadcrumb from '../partial/Breadcrumb';
    import flatPickr from 'vue-flatpickr-component'

    import VueApexCharts from 'vue-apexcharts'
	import apexChatData from './apexChartData.js'
	import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import Datepicker from 'vuejs-datepicker'
    import Indirect from './single/indirect/List'
	import globalAdmin from '../model/globalAdmin';

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,

          	VueApexCharts,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	Datepicker,

          	Indirect
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	labels: { "itees-organisations-edit": "" },

			    selectedStatus: {
			    	title: null,
			   		value: null
			    },
			    selectStatus: [
			    	{title: 'Inactive', value: 0},			    	
			    	{title: 'Active', value: 1},
			    ],

			    selectedCountry: null,
			    selectCountry: [],
			    selectedClient: null,
		        optionsClient: [
		          { text: 'Yes', value: '1' },
		          { text: 'No', value: '0' },
		        ],
		        selectedPartner: null,
		        optionsPartner: [
		          { text: 'Yes', value: '1' },
		          { text: 'No', value: '0' },
		        ],

		        isEditing: true,
		        isDisabled: true,
            	disableField: 'disabled-field',	
            	detail: [],
		        tabIndex: null,

		        // char
		        tabIndexAlumni: 0,
		    	charHead: {},

	            apexChatData,
	           
	            selectedTypeChart: {
			   		title: 'Month',
			   		value: 2
			   	},
	            optionsTypeChart: [
	            	{ title: 'Custom Date Range', value: 1 },
	            	{ title: 'Month', value: 2 },
	            	{ title: 'Year', value: 3 },
	            	{ title: 'Last 5 Years', value: 4 },
	            ],
				permission: [],
	            // day
	            rangePicker: [],
	            day_start: null,
	            day_end: null,
	            // month
	            selectedMonthYear: null,
	            charMonthY: null,
	            // year
	            selectedYear: null,
	            charYear: null,

	            tabAlumi: 1,

	            groupLeaders: [],
	            groupSpe: [],

	            series: [],
	            chartOptions: {
	            	chart: {
				        type: 'bar',
				        height: '600',
				        stacked: true,
				        toolbar: {
				          show: false,
				        },
			        	autoSelected: 'pan',
			      	},
			      	xaxis: {
			      		labels: {
						    show: true,
						    rotate: -35,
						    rotateAlways: true,
						},
			        	categories: [],
			      	},
			      	yaxis: {
				        stepSize: 50,
				        min: 0,
				        max: 500,
			      	},	  
	            },
	            // activity_contribution
	            fields_activity_contribution: [
	            	{ key: 'activity_id', label: 'ID.', isSort: true },		        
			        { key: 'activity_name', label: 'Activity Title', isSort: false },
			        { key: 'project_name', label: 'Project Name', isSort: false },
			        { key: 'number_leader', label: 'Leaders', isSort: true },
			        { key: 'number_specialist', label: 'Specialist', isSort: true },
	            ],
	            activity_contribution: [],
	            sort: {"field": "activity_id","type": "asc"},
	            perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',

    			flatMess: false
            }
		},	
		watch: {
			"$route": function(val) {
				if(val){
					this.featchItem()
					if(val.name == 'itees-organisations-edit'){
						this.isDisabled = false
            			this.disableField = ''
					}
				}
			},
			tabIndex: function(val){
				if(val == 1){
              		this.series.splice(0);  // xóa hết các item đã có
					this.series.push(
              			{ name: 'Leaders', data: this.groupLeaders }
          			)
          			this.series.push(
              			{ name: 'Speacialist', data: this.groupSpe }
          			)        			
				}
			},
			tabIndexAlumni: function(val){
				if(val == 1){
					this.chartHead(2)
					this.tabAlumi = 2
				}
				else{
					this.chartHead(1)
					this.tabAlumi = 1
				}			
			},
			selectedTypeChart(val){
				if(val.value == 1){
					if(this.rangePicker.length == 1){
						this.day_start = this.rangePicker[0]
						this.chartDayRang_method()
					}
					else if(this.rangePicker.length > 1){
						let path = this.rangePicker.split(" to ")
						this.day_start = path[0]
						this.day_end = path[1]
						this.chartDayRang_method()
					}
				}
				if(val.value == 2){
					if(this.selectedMonthYear){
						this.charMonthY = moment(this.selectedMonthYear).format('YYYY-MM')
						this.chartMonth_Method()
					}
				}
				if(val.value == 3){
					if(this.selectedYear){
						this.charYear = moment(this.selectedYear).format('YYYY')
						this.chartYear_Method()
					}					
				}	
				if(val.value == 4){
					this.chartLast5Years_Method()
				}
			},
			rangePicker(val){
				if(val.length > 1){
					let path = val.split(" to ")
					this.day_start = path[0]
					this.day_end = path[1]
				}
				else{
					this.day_start = this.rangePicker[0]
				}
				this.chartDayRang_method()
			},			
			selectedMonthYear(val){
				this.charMonthY = moment(this.selectedMonthYear).format('YYYY-MM')
				this.chartMonth_Method()
			},
			selectedYear(val){
				this.charYear = moment(this.selectedYear).format('YYYY')
				this.chartYear_Method()
			},
			currentPage(val){
				this.chart_activity_contribution()
			},
			selected(val){
				if(val){
					this.perPage = val
					this.chart_activity_contribution()
				}
			},
		},
		mounted () {
		    this.selected = '10'
		},
		created(){
			if(this.$route && this.$route.name == 'itees-organisations-detail'){
				this.labels = {'itees-organisations-detail': ''}
			}
			if(this.$route && this.$route.name == 'itees-organisations-edit'){
				this.isDisabled = false
    			this.disableField = ''
			}
			
			this.permission = this.checkPermission(this.userData,"Organisation");

			this.listAllCountry()
			this.featchItem()

			this.chartHead(1)
			this.today()
			this.chart_activity_contribution()
		},
		methods: {	
			alert(){
				if(!this.flatMess){
					this.$toast({
	                  	component: ToastificationContent,
	                  	props: {
	                    	title: 'Please select the day less than 31 days',
	                    	variant: 'danger',
	                  	},
	                },
	                {
	                  	position: 'top-center'
	                })
	                this.flatMess = true
				}		 	
		   	},
		   	setMess(){
		   		this.flatMess = false
		   	},
			toEdit(id){
				this.$router.push(`/admin/organisations/edit/${id}`)
			},
			length1000(e){
		        return String(e).substring(0,1000)
		    },
		    length100(e){
		        return String(e).substring(0,100)
		    }, 
		    length50(e){
		        return String(e).substring(0,50)
		    }, 
			featchItem(){
	            this.$store
	            .dispatch('auth/detailOrganisation', {
	              	id: this.$route.params.id  
	            })
	            .then(response => {
	              	this.detail = response.data.data
	              	this.labels['itees-organisations-detail'] = this.detail.name
	              	this.labels['itees-organisations-edit'] = this.detail.name
	              	document.title = response.data.data.name + ' | Organization - ITEES | Your Global Partner of Choice'	
	              	if(this.detail.country_id) this.getCountrybyId(this.detail.country_id)
	              	
	              	if(this.detail.status == 0) {
						this.selectedStatus.title = 'Inactive'
						this.selectedStatus.value = 0					
					}
					if(this.detail.status == 1) {						
						this.selectedStatus.title = 'Active'			
						this.selectedStatus.value = 1			
					}

					if( this.detail.is_client != null ) this.selectedClient = this.detail.is_client

					if(this.detail.is_partner != null) this.selectedPartner = this.detail.is_partner
						
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },			
	        getCountrybyId(id){
				this.$store
				.dispatch('auth/detailCountry', {
					id: id
				})
				.then(response => {
					this.selectedCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchCountry')
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},		
			editAction(){
	          	this.isDisabled = false
	          	this.isEditing = false
	          	this.disableField= ''
			},
			cancelAction(){
               	this.$router.push({ name: 'itees-organisations' })
			},
			saveAction(){
				this.$refs.editOr_rel.validate().then(success => {
					if(success){
						let datas_edit_or = {
							name: this.detail.name,
							abbreviation: this.detail.abbreviation,
							is_client: this.selectedClient,
							is_partner: this.selectedPartner,
							country_id: this.selectedCountry ? this.selectedCountry.id : null,
							description: this.detail.description,
							status: this.selectedStatus ? this.selectedStatus.value : null,
						}
						this.$store
			                .dispatch('auth/editOr', {
			                	id: this.$route.params.id,
								data: datas_edit_or
			                })
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.detail.name + ' Updated!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })

			                	this.$router.push({ name: 'itees-organisations' })
			                	
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                })  
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			},

			// char
			today(){            
              	var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM')
              	var cat = moment(d).format('MM/YYYY')
              	this.selectedMonthYear = now
              	this.chartOptions.xaxis.categories.push(cat)
          	},
			chartHead(type){
	            this.$store
	            .dispatch('auth/chartHead_detail_Or', {
	            	id: this.$route.params.id,
	              	type: type
	            })
	            .then(response => {
	              	this.charHead = response.data.data
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        // chart_Or
	        chartDayRang_method(){
	            this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	start_date: this.day_start,
	              	end_date: this.day_end,
	              	type_date: 'day'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},
				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

					if(this.flatMess){
						this.chartOptions.xaxis.categories.splice(0)  
						this.chartOptions.xaxis.categories.push('')
						this.series.splice(0)
					}
					else{
						this.chartOptions.xaxis.categories.splice(0)  

	          			for (const value of response.data.data.array_month) {
						  	this.chartOptions.xaxis.categories.push(value)
						} 

		              	this.groupLeaders = response.data.data.array_leader
		              	this.groupSpe = response.data.data.array_trainer
		              	
		              	this.series.splice(0)  // xóa hết các item đã có
						this.series.push(
	              			{ name: 'Leaders', data: this.groupLeaders }
	          			)
	          			this.series.push(
	              			{ name: 'Speacialist', data: this.groupSpe }
	          			)
					}
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartMonth_Method(){
	            this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	month: this.charMonthY,
	              	type_date: 'month'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

					this.chartOptions.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions.xaxis.categories.push(value)
					} 

	              	this.groupLeaders = response.data.data.array_leader
	              	this.groupSpe = response.data.data.array_trainer

	              	
	              	this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
              			{ name: 'Leaders', data: this.groupLeaders }
          			)
          			this.series.push(
              			{ name: 'Speacialist', data: this.groupSpe }
          			)
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartYear_Method(){
	        	this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	year: this.charYear,
	              	type_date: 'year'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},
				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

					this.chartOptions.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions.xaxis.categories.push(value)
					}

	              	this.groupLeaders = response.data.data.array_leader
	              	this.groupSpe = response.data.data.array_trainer

	              	
	              	this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
              			{ name: 'Leaders', data: this.groupLeaders }
          			)
          			this.series.push(
              			{ name: 'Speacialist', data: this.groupSpe }
          			)
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartLast5Years_Method(){
	        	this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	type_date: 'last5years'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

					this.chartOptions.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions.xaxis.categories.push(value)
					} 

	              	this.groupLeaders = response.data.data.array_leader
	              	this.groupSpe = response.data.data.array_trainer

	              	
	              	this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
              			{ name: 'Leaders', data: this.groupLeaders }
          			)
          			this.series.push(
              			{ name: 'Speacialist', data: this.groupSpe }
          			)
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },

	        chart_activity_contribution(){
	        	this.$store
				.dispatch('auth/chartActivityContribution_Or', {
					id: this.$route.params.id,
					type: this.tabAlumi,
					sort: this.sort,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {
					this.activity_contribution = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
	        },
			headClicked(key, isSort){
				if(isSort.isSort == true){
					if(this.sort.field == key) {
						if(this.sort.type == 'asc') this.sort.type = 'desc'
						else this.sort.type = 'asc'
					} else {
						this.sort.field = key
						this.sort.type = 'desc'
					}	
					this.chart_activity_contribution()
				}
			}
		},	
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	@import '@core/scss/vue/libs/vue-flatpicker.scss';

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>