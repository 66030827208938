<template>
	<section>
		<b-card no-body class="mb-20">
			<b-card-header class="pt-2 pb-1 pl-2 frm-basic">
				<b-row style="width: 100%; margin: 0;">
					<b-col lg="4" class="px-0">  
						<b-card-title>Latest Statistics</b-card-title>
					</b-col>	
		        	<b-col lg="8" class="cadendar-chart" style="display: flex; justify-content: flex-end;">  
	                    <b-form-group style="width: 230px; display: inline-block" class="mb-0 mr-1">	
                            <v-select
                            	label="title"
                                :options="optionsTypeChart_2"
                                v-model="selectedTypeChart_2" 
                            />
                        </b-form-group>  

	                    <div class="border-chart" v-if="selectedTypeChart_2.value == 1">
				        	<feather-icon
					          icon="CalendarIcon"
					          size="14"
					        />
				        	<flat-pickr
					          	v-model="rangePicker_2"
					          	:config="{ 
					          		mode: 'range', altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d',
					          		onChange: function (selectedDates, dateStr, instance) {

								        if (selectedDates.length == 2) {
								        	this.flatMess = false
								            var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
								            range = range / 86400;

								            if(range > 30)
								            {		                
								                instance.clear()
								                alert();
								            }          
								        }
								    },
								    onOpen: [
								    	function(selectedDates, dateStr, instance){
						            		setMess()
						        		},
						      		],
					          	}"

					          	class="form-control flat-picker bg-transparent border-0 shadow-none"
					          	placeholder="Select Date"
					        />
                        </div>   
                        <div class="border-chart pl-0" v-else-if="selectedTypeChart_2.value == 2">
				        	<datepicker        
					        	v-model="selectedMonthYear_2"
                            	format="MM/yyyy" 
                            	minimum-view="month"
                            	placeholder="Select Month"
                            	class="w-100-child"
                            >              	
                            </datepicker>
                        </div>  
                        <div class="border-chart pl-0" v-else-if="selectedTypeChart_2.value == 3">
				        	<datepicker        
					        	v-model="selectedYear_2"
                            	format="yyyy" 
                            	minimum-view="year"
                            	placeholder="Select Year"
                            	class="w-100-child"
                            >              	
                            </datepicker>
                        </div>  
                  	</b-col> 
		        </b-row>	
			</b-card-header>
		    <b-card-body class="pb-1" v-if="tabChart == 1">
			    <vue-apex-charts
			        height="600"
			        :options="chartOptions_2"
			        :series="series_2"
			        show="true"
			    />
		    </b-card-body>
		</b-card>
		<b-card class="px-table-0 w-15-percent-cl-1 w-20-percent-cl-2 w-30-percent-cl-3">
        	<b-row>
	            <b-col lg="6" style="padding-left: 30px ">
	                <h2 class="mb-2"><strong>Activity Contribution</strong></h2>
	            </b-col>
	        </b-row>
		    <b-table small :fields="fields_country_Breakdown" :items="country_Breakdown" tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked_Break">
		    	<template #head(activity_id)="data">
		    		<span>{{ data.label }}</span>
		    		<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort_Break.field && sort_Break.type == 'desc'}]" style="position: relative; left: 45px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
		    	</template>
		    	<template #head(activity_name)="data">
		    		<span>{{ data.label }}</span>
		    	</template>	
		    	<template #head(project_name)="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>		
		    	<template #head()="data">
		    		<div class="d-block text-center">
		    			<span>{{ data.label }}</span>
		    			<span :class="['cursor-pointer iconsort', {sort: data.field.key == sort_Break.field && sort_Break.type == 'desc'}]" style="position: relative; left: 30px;">
				        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
				        </span>
		    		</div>
		    	</template>	
		    	<template #cell(activity_id)="data">
					<span>{{ data.item.activity_id }}</span>
				</template>	
		    	<template #cell(activity_name)="data">
					<span>{{ data.item.activity_name }}</span>
				</template>	
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>				
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			</b-table>
		</b-card>
		<div v-if="this.total_Break > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage_Break"
		      	:total-rows="total_Break"
		      	:per-page="perPage_Break"
		      	aria-controls="custom-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected_Break" class="cursor-pointer">
				    <option v-for="limit in limitpage_Break">{{ limit }}</option>
				</select>			  
		    </div>
		</div> 
	</section>
</template>

<script>
	const moment = require('moment')
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	

    import VueApexCharts from 'vue-apexcharts'
	import apexChatData_2 from './apexChartData.js'
	import flatPickr from 'vue-flatpickr-component'
	import vSelect from 'vue-select'
	import Datepicker from 'vuejs-datepicker'

	export default {
	  	components: {
	  		flatPickr,
          	VueApexCharts,
          	apexchart: VueApexCharts,
          	vSelect,
          	Datepicker,
	  	},
	  	props: {
            tabChart: {
                type: Number,
                required: false,
                default: () => ''
            },
        },
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),

	            fields_country_Breakdown: [
                	{ key: 'activity_id', label: 'ID.', isSort: true },		        
			        { key: 'activity_name', label: 'Activity Title', isSort: false },
			        { key: 'project_name', label: 'Project Name', isSort: false },
			        { key: 'number_leader', label: 'Leaders', isSort: true },
			        { key: 'number_specialist', label: 'Specialist', isSort: true },
			    ],
		    	country_Breakdown: [],

	            apexChatData_2,
	           
	            selectedTypeChart_2: {
			   		title: 'Month',
			   		value: 2
			   	},
	            optionsTypeChart_2: [
	            	{ title: 'Custom Date Range', value: 1 },
	            	{ title: 'Month', value: 2 },
	            	{ title: 'Year', value: 3 },
	            	{ title: 'Last 5 Years', value: 4 },
	            ],

	            // day
	            rangePicker_2: [],
	            day_start: null,
	            day_end: null,
	            // month
	            selectedMonthYear_2: null,
	            charMonthY_2: null,
	            // year
	            selectedYear_2: null,
	            charYear_2: null,

	            tabAlumi: 2,

	            groupLeaders_2: [],
	            groupSpe_2: [],

	            series_2: [],
	            chartOptions_2: {
	            	chart: {
				        type: 'bar',
				        height: '600',
				        stacked: true,
				        toolbar: {
				          show: false,
				        },
			        	autoSelected: 'pan',
			      	},
			      	xaxis: {
			      		labels: {
						    show: true,
						    rotate: -35,
						    rotateAlways: true,
						},
			        	categories: [],
			      	},
			      	yaxis: {
				        stepSize: 50,
				        min: 0,
				        max: 500,
			      	},	     
	            },

	            // Breakdown
	            sort_Break: {"field": "activity_id","type": "asc"},
	            perPage_Break: 10,
        		currentPage_Break: 1, 
        		total_Break: 0,
        		limitpage_Break: ['10', '20', '30'],
    			selected_Break: '10',

    			flatMess: false
            }
		},	
		watch: {
			tabChart(val){
				if(val == 1 && this.selectedTypeChart_2.value == 1){
					if(this.rangePicker_2 && this.rangePicker_2.length == 1){
						this.day_start = this.rangePicker_2[0]
						this.chartDayRang_method_2()
					}
					else if(this.rangePicker_2 && this.rangePicker_2.length > 1){
						let path = this.rangePicker_2.split(" to ")
						this.day_start = path[0]
						this.day_end = path[1]
						this.chartDayRang_method_2()
					}
				}
			},
			selectedTypeChart_2(val){
				if(val.value == 1){
					if(this.rangePicker_2 && this.rangePicker_2.length == 1){
						this.day_start = this.rangePicker_2[0]
						this.chartDayRang_method_2()
					}
					else if(this.rangePicker_2 && this.rangePicker_2.length > 1){
						let path = this.rangePicker_2.split(" to ")
						this.day_start = path[0]
						this.day_end = path[1]
						this.chartDayRang_method_2()
					}
				}
				if(val.value == 2){
					if(this.selectedMonthYear_2){
						this.charMonthY_2 = moment(this.selectedMonthYear_2).format('YYYY-MM')
						this.chartMonth_Method_2()
					}
				}
				if(val.value == 3){
					if(this.selectedYear_2){
						this.charYear_2 = moment(this.selectedYear_2).format('YYYY')
						this.chartYear_Method_2()
					}					
				}	
				if(val.value == 4){
					this.chartLast5Years_Method_2()
				}
			},
			rangePicker_2(val){
				if(val.length > 1){
					let path = val.split(" to ")
					this.day_start = path[0]
					this.day_end = path[1]
				}
				else{
					this.day_start = this.rangePicker_2[0]
				}
				this.chartDayRang_method_2()
			},			
			selectedMonthYear_2(val){
				this.charMonthY_2 = moment(this.selectedMonthYear_2).format('YYYY-MM')
				this.chartMonth_Method_2()
			},
			selectedYear_2(val){
				this.charYear_2 = moment(this.selectedYear_2).format('YYYY')
				this.chartYear_Method_2()
			},
			currentPage_Break(val){
				this.chartBreakDown_Or_2()
			},
			selected_Break(val){
				if(val){
					this.perPage_Break = val
					this.chartBreakDown_Or_2()
				}				
			},
		},
		mounted () {
		    this.selected = '10'
		    this.selected_Break = '10'
		},
		created(){
			this.today()
			this.chartBreakDown_Or_2()
		},
		methods: {
			alert(){
				if(!this.flatMess){
					this.$toast({
	                  	component: ToastificationContent,
	                  	props: {
	                    	title: 'Please select the day less than 31 days',
	                    	variant: 'danger',
	                  	},
	                },
	                {
	                  	position: 'top-center'
	                })
	                this.flatMess = true
				}		 	
		   	},
		   	setMess(){
		   		this.flatMess = false
		   	},
			today(){            
              	var d = new Date()
              	d.setDate(d.getDate())
              	var now = moment(d).format('YYYY-MM')
              	var cat = moment(d).format('MM/YYYY')
              	this.selectedMonthYear_2 = now
              	this.chartOptions_2.xaxis.categories.push(cat)
          	},
	        // chart_Or
	        chartDayRang_method_2(){
	            this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	start_date: this.day_start,
	              	end_date: this.day_end,
	              	type_date: 'day'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions_2 = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

					if(this.flatMess){
						this.chartOptions_2.xaxis.categories.splice(0) 
						this.chartOptions_2.xaxis.categories.push('') 
						this.series_2.splice(0)
					}
					else{
						this.groupLeaders_2 = response.data.data.array_leader
		              	this.groupSpe_2 = response.data.data.array_trainer
		              	
		              	this.series_2.splice(0)  // xóa hết các item đã có
						this.series_2.push(
	              			{ name: 'Leaders', data: this.groupLeaders_2 }
	          			)
	          			this.series_2.push(
	              			{ name: 'Speacialist', data: this.groupSpe_2 }
	          			)   

	          			this.chartOptions_2.xaxis.categories.splice(0)  

	          			for (const value of response.data.data.array_month) {
						  	this.chartOptions_2.xaxis.categories.push(value)
						} 
					}
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartMonth_Method_2(){
	            this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	month: this.charMonthY_2,
	              	type_date: 'month'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions_2 = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

	              	this.groupLeaders_2 = response.data.data.array_leader
	              	this.groupSpe_2 = response.data.data.array_trainer

	              	
	              	this.series_2.splice(0)  // xóa hết các item đã có
					this.series_2.push(
              			{ name: 'Leaders', data: this.groupLeaders_2 }
          			)
          			this.series_2.push(
              			{ name: 'Speacialist', data: this.groupSpe_2 }
          			)   

          			this.chartOptions_2.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions_2.xaxis.categories.push(value)
					} 
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartYear_Method_2(){
	        	this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	year: this.charYear_2,
	              	type_date: 'year'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions_2 = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

	              	this.groupLeaders_2 = response.data.data.array_leader
	              	this.groupSpe_2 = response.data.data.array_trainer

	              	
	              	this.series_2.splice(0)
					this.series_2.push(
              			{ name: 'Leaders', data: this.groupLeaders_2 }
          			)
          			this.series_2.push(
              			{ name: 'Speacialist', data: this.groupSpe_2 }
          			)   

          			this.chartOptions_2.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions_2.xaxis.categories.push(value)
					} 
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartLast5Years_Method_2(){
	        	this.$store
	            .dispatch('auth/chartDetail_Or', {
	            	id: this.$route.params.id,
	              	type: this.tabAlumi,
	              	type_date: 'last5years'
	            })
	            .then(response => {

	            	var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if(countNumber <= 2){
						plusNum = parseInt( (firstNum * 0) + 100 )
					}
					else if(countNumber == 3){
						plusNum = parseInt( (firstNum * 100) + 100 )
					}
					else if(countNumber == 4){
						plusNum = parseInt( (firstNum * 1000) + 1000 )
					}
					else if(countNumber == 5){
						plusNum = parseInt( (firstNum * 10000) + 10000 )
					}
					else{
						plusNum = number
					}

					this.chartOptions_2 = {
						chart: {
					        type: 'bar',
					        height: '600',
					        stacked: true,
					        toolbar: {
					          show: false,
					        },
				        	autoSelected: 'pan',
				      	},
				      	colors: ['#8471FF', '#F73DA1'],
				      	plotOptions: {
					        bar: {
					          	columnWidth: '50%',
					          	colors: {
						            backgroundBarColors: [
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						              'transparent',
						            ],
					          	},
				          
				        	},
				      	},
					    dataLabels: {
					        enabled: false,
					    },
				      	legend: {
					        show: true,
					        position: 'bottom',
					        fontSize: '14px',
					        horizontalAlign: 'center',
					        itemMargin: {
					            horizontal: 10,
					            vertical: 25
					        },
				      	},
				      	stroke: {
					        show: true,
					        colors: ['transparent'],
				      	},
				      	grid: {
					        xaxis: {
					          lines: {
					            show: true,
					          },
					        },
				      	},
				      	fill: {
				        	opacity: 1,
				      	}, 
						xaxis: {
				      		labels: {
							    show: true,
							    rotate: -35,
							    rotateAlways: true,
							},
				        	categories: [],
				      	},
				      	yaxis: {
					        stepSize: 50,
					        min: 0,
					        max: plusNum,
				      	}, 
					};

	              	this.groupLeaders_2 = response.data.data.array_leader
	              	this.groupSpe_2 = response.data.data.array_trainer

	              	
	              	this.series_2.splice(0)
					this.series_2.push(
              			{ name: 'Leaders', data: this.groupLeaders_2 }
          			)
          			this.series_2.push(
              			{ name: 'Speacialist', data: this.groupSpe_2 }
          			)   

          			this.chartOptions_2.xaxis.categories.splice(0)  

          			for (const value of response.data.data.array_month) {
					  	this.chartOptions_2.xaxis.categories.push(value)
					} 
	            })
	            .catch((error) => {
	              	console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
	            })
	        },
	        chartBreakDown_Or_2(){
	        	this.$store
				.dispatch('auth/chartActivityContribution_Or', {
					id: this.$route.params.id,
					type: this.tabAlumi,
					sort: this.sort_Break,
					limit: this.perPage_Break,
					page: this.currentPage_Break,
				})
				.then(response => {
					this.country_Breakdown = response.data.data.data
					this.total_Break = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
	        },
			headClicked_Break(key, isSort){
				if(isSort.isSort == true){
					if(this.sort_Break.field == key) {
						if(this.sort_Break.type == 'asc') this.sort_Break.type = 'desc'
						else this.sort_Break.type = 'asc'
					} else {					
						this.sort_Break.field = key
						this.sort_Break.type = 'desc'
					}
					this.chartBreakDown_Or_2()
				}
			}
		},
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	@import '@core/scss/vue/libs/vue-flatpicker.scss';

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
</style>