import { $themeColors } from '@themeConfig'

// heat chart data generator
function generateDataHeatMap(count, yrange) {
  let i = 0
  const series = []
  while (i < count) {
    const x = `w${(i + 1).toString()}`
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    // series.push({
    //   x,
    //   y,
    // })
    // eslint-disable-next-line no-plusplus
    i++
  }
  return series
}

export default {}
